import App from 'next/app';
import React from 'react';

import 'styling/common.scss';
import 'styling/[blog-slug].scss';
import 'styling/[career-slug].scss';
import 'styling/[case-slug].scss';
import 'styling/[filename].scss';
import 'styling/[team-slug].scss';
import 'styling/_error.scss';
import 'styling/advantage.scss';
import 'styling/blog.scss';
import 'styling/bloglist.scss';
import 'styling/buttons.scss';
import 'styling/career.scss';
import 'styling/caselist.scss';
import 'styling/cases.scss';
import 'styling/construction.scss';
import 'styling/contact.scss';
import 'styling/cookie-policy.scss';
import 'styling/cookies.scss';
import 'styling/ExtraNews.scss';
import 'styling/filters.scss';
import 'styling/footer.scss';
import 'styling/form.scss';
import 'styling/hero.scss';
import 'styling/index.scss';
import 'styling/more-cases.scss';
import 'styling/news-letter.scss';
import 'styling/parallax.scss';
import 'styling/privacy.scss';
import 'styling/project.scss';
import 'styling/redactor-content.scss';
import 'styling/reference.scss';
import 'styling/services.scss';
import 'styling/team.scss';
import 'styling/team-block.scss';
import 'styling/full-width-block.scss';
import 'styling/team-services.scss';
import 'styling/[specialty-slug].scss';
import 'styling/text.scss';
import 'styling/troeven-block.scss';
import 'styling/modal.scss';
import 'styling/specialty-navigation.scss';
import 'react-phone-number-input/style.css';

import lozad from 'lozad';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isProduction } from 'utils/common';

export let lozadObserver;
export default class MyApp extends App {
  componentDidMount(): void {
    lozadObserver = lozad('.lozad', {
      rootMargin: '0px 0px 300px',
      loaded: (el) => el.classList.add('loaded'),
    }); // lazy loads elements with default selector as ".lozad"
    lozadObserver.observe();
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      >
        <Component {...pageProps} />
      </GoogleReCaptchaProvider>
    );
  }
}
